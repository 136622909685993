import axios from 'axios';
import global from '../../components/Global';

let base = global.doartSystemBase;

export const createSysJobInfo = params => { return axios.post(base + '/sysJob/createSysJobInfo', params)};

export const deleteSysJobInfo = params => { return axios.post(base + '/sysJob/deleteSysJobInfo', params)};

export const updateSysJobInfo = params => { return axios.post(base + '/sysJob/updateSysJobInfo', params)};

export const querySysJobInfoList = params => { return axios.post(base + '/sysJob/querySysJobInfoList', params)};

export const querySysJobInfo = params => { return axios.post(base + '/sysJob/querySysJobInfo', params)};

export const executeJob = params => { return axios.post(base + '/sysJob/executeJob', params)};

export const pauseJob = params => { return axios.post(base + '/sysJob/pauseJob', params)};

export const resumeJob = params => { return axios.post(base + '/sysJob/resumeJob', params)};
