<template>
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" top="0"
               @close="closeSysJobDetailPage" width="850px" :close-on-click-modal="false" center>
        <el-form :model="sysJobDto" :rules="formRules" ref="sysJobForm" label-width="120px" size="mini">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="任务名称" prop="triggerName">
                        <el-input v-model="sysJobDto.triggerName" autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10" :offset="2">
                    <el-form-item label="执行频率" prop="cronExpression">
                        <el-input v-model="sysJobDto.cronExpression" autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="任务描述" prop="description">
                        <el-input v-model="sysJobDto.description" autocomplete="off" :show-word-limit="true"
                            maxlength="250" type="textarea" :rows="4"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="触发方式" prop="triggerGroup">
                        <el-select v-model="sysJobDto.triggerGroup" placeholder="请选择" @change="triggerGroupChangeEventHandler">
                            <el-option v-for="item in triggerGroup" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="sysJobDto.triggerGroup === 'com.doart.job.SysScheduleForFeignJob'">
                <el-col :span="22">
                    <el-form-item label="服务名称" prop="serviceName">
                        <el-input v-model="sysJobDto.serviceName" autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="sysJobDto.triggerGroup === 'com.doart.job.SysScheduleForFeignJob'">
                <el-col :span="22">
                    <el-form-item label="请求路径" prop="url">
                        <el-input v-model="sysJobDto.url" autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="sysJobDto.triggerGroup === 'com.doart.job.SysScheduleForQueueJob'">
                <el-col :span="22">
                    <el-form-item label="交换机名称" prop="exchange">
                        <el-input v-model="sysJobDto.exchange" autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="sysJobDto.triggerGroup === 'com.doart.job.SysScheduleForQueueJob'">
                <el-col :span="22">
                    <el-form-item label="路由名称" prop="route">
                        <el-input v-model="sysJobDto.route" autocomplete="off" maxlength="255"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="sysJobDto.triggerGroup === 'com.doart.job.SysScheduleForQueueJob'">
                <el-col :span="22">
                    <el-form-item label="消息信息" prop="description">
                        <el-input v-model="sysJobDto.mqData" autocomplete="off" :show-word-limit="true"
                            maxlength="250" type="textarea" :rows="4"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="saveOrModifySysJob">确定</el-button>
            <el-button size="mini" @click="detailDialogVisible = false">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import * as sysJobApi from '@/api/sys/jobApi';
import message from '@/utils/message';
import global from '@/components/Global';

export default {

    name: 'SysJobDetail',
    data() {

        return {

            detailDialogTitle: '',
            detailDialogVisible: false,
            triggerGroup: [
                {
                    label: 'Feign接口触发',
                    value: 'com.doart.job.SysScheduleForFeignJob'
                },
                {
                    label: '消息队列触发',
                    value: 'com.doart.job.SysScheduleForQueueJob'
                }
            ],
            sysJobDto: {

                triggerName: '',
                triggerGroup: 'com.doart.job.SysScheduleForFeignJob',
                oldTriggerName: '',
                oldTriggerGroup: '',
                description: '',
                cronExpression: '',
                serviceName: '',
                url: '',
                exchange: '',
                route: '',
                mqData: ''
            },
            formRules: {

                triggerName: [{required: true, trigger: 'blur', message: '任务名称不能为空'}],
                cronExpression: [{required: true, trigger: 'blur', message: '执行频率不能为空'}],
                serviceName: [{required: true, trigger: 'blur', message: '微服务名称不能为空'}],
                url: [{required: true, trigger: 'blur', message: '请求路径不能为空'}],
                exchange: [{required: true, trigger: 'blur', message: '交换机名称不能为空'}],
                route: [{required: true, trigger: 'blur', message: '路由名称不能为空'}],
                mqData: [{required: true, trigger: 'blur', message: '消息内容不能为空'}]
            }
        };
    },
    methods: {

        initAddSysJobDetailPage() {

            let that = this;
            that.detailDialogTitle = '新增';
            that.detailDialogVisible = true;
        },
        initEditSysJobDetailPage(sysJobDto) {

            let that = this;
            that.detailDialogTitle = '修改';
            that.detailDialogVisible = true;
            sysJobApi.querySysJobInfo(sysJobDto).then(res => {

                if (res.code === global.response_status_success_obj) {

                    that.sysJobDto.triggerName = res.data.triggerName;
                    that.sysJobDto.triggerGroup = res.data.triggerGroup;
                    that.sysJobDto.oldTriggerName = res.data.triggerName;
                    that.sysJobDto.oldTriggerGroup = res.data.triggerGroup;
                    that.sysJobDto.description = res.data.description;
                    that.sysJobDto.cronExpression = res.data.cronExpression;
                    that.sysJobDto.serviceName = res.data.serviceName;
                    that.sysJobDto.url = res.data.url;
                    that.sysJobDto.exchange = res.data.exchange;
                    that.sysJobDto.route = res.data.route;
                    that.sysJobDto.mqData = res.data.mqData;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败，请重试');
                console.error(err);
            });
        },
        saveOrModifySysJob() {

            this.$refs['sysJobForm'].validate((valid) => {

                if (valid) {

                    if (this.detailDialogTitle === '新增') {

                        sysJobApi.createSysJobInfo(this.sysJobDto).then(res => {

                            if (res.code === global.response_status_success_obj) {

                                this.$emit('getTableData');
                                message.success(this.detailDialogTitle + '成功');
                                this.detailDialogVisible = false;
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => {

                            message.error('请求失败，请重试');
                            console.error(err);
                        });
                    } else {

                        sysJobApi.updateSysJobInfo(this.sysJobDto).then(res => {

                            if (res.code === global.response_status_success_obj) {

                                this.$emit('getTableData');
                                message.success(this.detailDialogTitle + '成功');
                                this.detailDialogVisible = false;
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => {

                            message.error('请求失败，请重试');
                            console.error(err);
                        });
                    }
                } else {

                    return false;
                }
            });
        },
        closeSysJobDetailPage() {

            this.$refs['sysJobForm'].resetFields();
        },
        triggerGroupChangeEventHandler() {

            this.$refs['sysJobForm'].clearValidate([

                'serviceName',
                'url',
                'exchange',
                'route',
                'mqData'
            ]);
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
