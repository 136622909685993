<template>
    <div>
        <div class="panel-style">
            <el-form :inline="true" :model="searchForm" size="mini">
                <el-form-item label="任务名称" prop="triggerName">
                    <el-input v-model="searchForm.triggerName" clearable autocomplete="off" placeholder="任务名称"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuerySysJobClickEvent">查询</el-button>
                    <el-button type="primary" @click="handleAddSysJobClickEvent">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table border stripe size="small" :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                :data="tableData" height="550px" v-loading="listLoading">
                <el-table-column prop="triggerName" label="名称" :show-overflow-tooltip="true"/>
                <el-table-column prop="triggerState" label="状态" align="center"  width="90px">
                    <template slot-scope="scope">
                        <div v-if="scope.row.triggerState === 'WAITING'" style="color: #67C23A">{{scope.row.triggerState}}</div>
                        <div v-else-if="scope.row.triggerState === 'ERROR'" style="color: #F56C6C">{{scope.row.triggerState}}</div>
                        <div v-else>{{scope.row.triggerState}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="cronExpression" label="频率时间" width="120px"/>
                <el-table-column prop="triggerGroup" label="触发方式" :formatter="triggerGroupFormat" width="120px" align="center"/>
                <el-table-column prop="startTime" label="开始时间" align="center" :formatter="dateFormat" width="136px" />
                <el-table-column prop="prevFireTime" label="上次执行时间" align="center" :formatter="dateFormat"  width="136px" />
                <el-table-column prop="nextFireTime" label="下次执行时间" align="center" :formatter="dateFormat"  width="136px" />
                <el-table-column label="操作" align="center" width="140px">
                    <template slot-scope="scope">
                        <div class="option-btn-div">
                            <i class="option-btn-normal el-icon-edit" @click="handleEditSysJobClickEvent(scope.$index, scope.row)"/>
                            <i class="option-btn-normal el-icon-video-play" @click="handleExecuteSysJobClickEvent(scope.$index, scope.row)"/>
                            <i class="option-btn-normal el-icon-video-pause" @click="handlePauseSysJobClickEvent(scope.$index, scope.row)"/>
                            <i class="option-btn-normal el-icon-refresh-left" @click="handleResumeSysJobClickEvent(scope.$index, scope.row)"/>
                            <i class="option-btn-normal el-icon-delete" style="color: #F56C6C" @click="handleDeleteSysJobClickEvent(scope.$index, scope.row)"/>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                    :current-page.sync="searchForm.pageNum"
                    :page-sizes="[10, 50, 100]"
                    :page-size="searchForm.pageSize"
                    @size-change="handlePageSizeChangeEvent"
                    @current-change="handlePageNumChangeEvent"/>
            </div>
            <sys-job-detail ref="sysJobDetailPage" @getTableData="getTableData"/>
        </div>
    </div>
</template>
<script>
import * as sysJobApi from '@/api/sys/jobApi';
import SysJobDetail from "@/views/sys/job/sys-job-detail";
import message from "@/utils/message";
import global from "@/components/Global";

export default {

    name: "SysJobList",
    components: {

        SysJobDetail
    },
    data() {

        return {

            listLoading: false,
            searchForm: {

                triggerName: '',
                pageNum: 1,
                pageSize: 10,
            },
            tableData: [],
            tableTotal: 0,
            attentionVisible: false
        };
    },
    mounted() {

        this.getTableData();
    },
    methods: {

        getTableData() {

            this.listLoading = true;
            sysJobApi.querySysJobInfoList(this.searchForm).then((res) => {

                this.tableTotal = res.data.total;
                this.tableData = res.data.result;
                this.listLoading = false;
            }).catch(err => {

                message.error('查询失败，请重试');
                console.error(err);
            });
        },
        triggerGroupFormat(row, column, cellValue) {

            if (cellValue === 'com.doart.job.SysScheduleForFeignJob') {

                return 'Feign接口触发';
            } else if (cellValue === 'com.doart.job.SysScheduleForQueueJob') {

                return '消息队列触发';
            }
        },
        dateFormat(row, column, cellValue) {

            let date = new Date(parseInt(cellValue));
            let year = date.getFullYear();
            let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min = date.getMinutes() < 10 ? '0' + date.getMinutes(): date.getMinutes();
            let sec = date.getSeconds() < 10 ? '0' + date.getSeconds(): date.getSeconds();
            return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
        },
        handleAddSysJobClickEvent() {

            this.$refs.sysJobDetailPage.initAddSysJobDetailPage();
        },
        handleEditSysJobClickEvent(index, row) {

            this.$refs.sysJobDetailPage.initEditSysJobDetailPage(row);
        },
        handleDeleteSysJobClickEvent: function(index, row) {

            this.$confirm('是否需要删除？', '提示', {type: 'warning'}).then(() => {

                let params = {

                    triggerName : row.triggerName,
                    triggerGroup : row.triggerGroup
                };
                sysJobApi.deleteSysJobInfo(params).then(res => {

                    if (res.code === global.response_status_success_obj) {

                        this.getTableData();
                        message.success(res.msg);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败，请重试');
                    console.error(err);
                });
            });
        },
        handleExecuteSysJobClickEvent(index, row) {

            let params = {

                triggerName : row.triggerName,
                triggerGroup : row.triggerGroup
            };
            sysJobApi.executeJob(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.getTableData();
                    message.success(res.msg);
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败，请重试');
                console.error(err);
            });
        },
        handlePauseSysJobClickEvent(index, row) {

            let params = {

                triggerName : row.triggerName,
                triggerGroup : row.triggerGroup
            };
            sysJobApi.pauseJob(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.getTableData();
                    message.success(res.msg);
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败，请重试');
                console.error(err);
            });
        },
        handleResumeSysJobClickEvent(index, row) {

            let params = {

                triggerName : row.triggerName,
                triggerGroup : row.triggerGroup
            };
            sysJobApi.resumeJob(params).then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.getTableData();
                    message.success(res.msg);
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败，请重试');
                console.error(err);
            });
        },
        handleQuerySysJobClickEvent() {

            this.searchForm.pageNum = 1;
            this.getTableData();
        },
        handlePageNumChangeEvent(val) {

            this.searchForm.pageNum = val;
            this.getTableData();
        },
        handlePageSizeChangeEvent(val) {

            this.searchForm.pageSize = val;
            this.getTableData();
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around;
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
</style>
